<script lang="ts" setup>
import { computed, onMounted, getCurrentInstance } from "vue";
import { getInstallationGroups } from "@/services/api/masterdata.api";
import useGrid27 from "@/composables/grid27.js";
import router from "@/router/index";

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageSize, totalItems, changedOptions } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

pageSize.value = 0;

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Id", value: "id", width: 300 },
  { text: "Referentie", value: "ref" },
  { text: "Data score", value: "dataScore", align: 'center' },
];
const headers = computed(() => _headers);

onMounted(() => {
  fetchFirstPage();
});

// eslint-disable-next-line no-unused-vars
async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  console.log("AppliedStatusses getData: ", pageNumber, pageSize, sortBy, sortDesc);
  const response = await getInstallationGroups();
  console.log("AppliedStatusses getData: ", response.data.installationGroups);
  return { data: { data: response.data.installationGroups, totalNumber: response.data.installationGroups.length, lastPage: 0, currentPage: 1 } };
}

async function editItem(id) {
  //console.log("InstallationDetailType editItem, key: ", id);
  router.push({
    name: "InstallationDetailType",
    params: { id: id },
  });
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <v-layout class="inner">
    <header class="app-welcome-message">
      <h1>InstallationGroup</h1>
      <p>Installatie detail type</p>
    </header>
    <v-container fluid pa-0 mt-4>
      <v-row>
        <v-col cols="12">
          <v-data-table
            dense
            :options.sync="gridOptions"
            :headers="headers"
            hide-default-footer
            :items="gridData"
            @update:options="changedOptions"
            :loading="gridIsLoading"
            :items-per-page="pageSize"
            :server-items-length="totalItems"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item.id)"> mdi-pencil </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
